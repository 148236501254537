import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as ruleInteger,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  required_if as rule_required_if,
  min_value as ruleMinValue,
  max_value as ruleMaxValue,
  numeric as rule_numeric,
} from 'vee-validate/dist/rules'
import vi from 'vee-validate/dist/locale/vi.json'

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard,
  validatorDouble,
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const requiredIf = extend('required-if', rule_required_if)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', {
  ...ruleInteger,
  message: '{_field_} phải là một số nguyên',
})

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const maxValue = extend('max-value', {
  ...ruleMaxValue,
  message: '{_field_} phải nhỏ hơn hoặc bằng {max}',
})

export const minValue = extend('min-value', {
  ...ruleMinValue,
  message: '{_field_} phải lớn hơn hoặc bằng {min}',
})

export const numeric = extend('numeric', rule_numeric)

export const double = extend('double', {
  validate: validatorDouble,
  message: '{_field_} phải là một số hợp lệ',
})

export const positive = extend('positive', {
  validate: validatorPositive,
  message: '{_field_} là số nguyên dương',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: '{_field_} phải có ít nhất một chữ hoa, một chữ thường, một ký tự đặc biệt và một số',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

export const after_or_equal = extend("after_or_equal", {
  validate(value, { startDate }) {
    return value >= startDate;
  },
  params: ["startDate"],
  message: "{_field_} phải lớn hơn bằng ngày bắt đầu."
});

export const positive_integer = extend("positive_integer", {
  validate(value) {
    return /^[1-9]\d*$/.test(value);
  },
  message: 'Giá trị phải là số nguyên dương.',
});

// Install English and Arabic localizations.
localize('vi', vi)
